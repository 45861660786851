@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;1,100&family=Poppins:wght@100;300;500&family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face {
  font-family: Malstrom;
  src: url('./assets/fonts/malstrom/Malstrom-Regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: Malstrom;
  src: url('./assets/fonts/malstrom/Malstrom-Bold.otf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: Malstrom;
  src: url('./assets/fonts/malstrom/Malstrom-Black.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Malstrom;
  src: url('./assets/fonts/malstrom/Malstrom-Light.otf');
  font-weight: 300;
  font-style: normal;
}

body {
  font-family: 'Malstrom', sans-serif;
  font-weight: 300;
  margin: 0;

  color: rgba(23, 25, 37, 1);
  /* background: linear-gradient(173.01deg, #FCEEE3 1.04%, #FFFEFC 1.05%, #FCE4DF 121.8%); */
  /* background-color: rgb(127, 55, 48);
  background-image: url(https://assets.getpartiful.com/backgrounds/galaxy/mobile.jpg), linear-gradient(to right bottom, rgb(22, 38, 51) 27%, 38.2695%, rgb(72, 109, 118) 46.539%, 58.922%, rgb(140, 136, 135) 65.305%, 77.6525%, rgb(171, 88, 82) 100%); */

  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gm-style-cc { display:none; }

.no-scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.no-scroll::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}